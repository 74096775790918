const templateVariantSettings = {
  header: "DESKTOP",
  homePage: "HomeTemplateV9",
  speedBump: {
    borderWithRadious: true,
  },
  websiteName: "First Point",
  about: null,
  contact: null,
  footer: null,
};

module.exports = templateVariantSettings;
